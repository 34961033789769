import PropTypes from 'prop-types';
import OverlayTooltip from '@/storychief/components/OverlayTooltip';
import StoryChief from '@/storychief';
import PointerEventsWrapper from './PointerEventsWrapper';

const propTypes = {
  children: PropTypes.node.isRequired,
  accessRight: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};
const defaultProps = {
  accessRight: null,
  tooltipPlacement: 'top',
};

const accessRightDenyMessages = {
  canManageDestinations:
    'You are not authorized to add channels. Ask a team member to add channels or request access.',
  canCreateStories:
    'You are not authorized to create stories. Ask a team member to request access.',
  canCreatePostsets:
    'You are not authorized to create social posts. Ask a team member to request access.',
  canInviteUsers:
    'You are not authorized to invite users. Ask a team member to invite a user or request access.',
  canCreateCampaigns:
    'You are not authorized to create campaigns. Ask a team member to request access.',
  canEditAccount:
    'You are not authorized to edit account settings. Ask a team member to request access.',
  canManageKeywords:
    'You are not authorized to manage keywords. Ask a team member to request access.',
};

function AccessRightTooltip({ accessRight, tooltipPlacement, children }) {
  const hasAccess = StoryChief.accessRight(accessRight) || !accessRight;

  function getMessage() {
    if (accessRight && accessRightDenyMessages[accessRight]) {
      return accessRightDenyMessages[accessRight];
    }

    return 'You are not authorized to perform this action. Ask a team member to request access.';
  }

  if (hasAccess) {
    return children;
  }

  return (
    <OverlayTooltip id={accessRight} placement={tooltipPlacement} content={getMessage()}>
      <PointerEventsWrapper className="d-inline-block">{children}</PointerEventsWrapper>
    </OverlayTooltip>
  );
}

AccessRightTooltip.propTypes = propTypes;
AccessRightTooltip.defaultProps = defaultProps;

export default AccessRightTooltip;
